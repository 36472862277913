import { useAtomValue } from "jotai";
import { walletModalAtom } from "src/store/modals";
import WalletModal from "./WalletModal";
import ClaimModals from "@/fleet/modals/Claim";

export default function MainModals() {
  const showWalletModal = useAtomValue(walletModalAtom);

  return (
    <>
      {showWalletModal && <WalletModal />}
      <ClaimModals />
    </>
  );
}
