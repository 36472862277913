import Button from "../../../UI/Button";
import Icon from "../../../UI/Icon";
import { CaretLeft } from "../../../UI/icons";

export default function BackButton({ text, hideCaret, onClick, ...rest }) {
  return (
    <Button onClick={onClick} color="#B2B2B2" {...rest}>
      {!hideCaret && (
        <Icon
          aria="Toggle modal close"
          transform="translateX(-100%) translateY(10%)"
        >
          <CaretLeft />
        </Icon>
      )}
      {text || "Go back"}
    </Button>
  );
}
