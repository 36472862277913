import DismissButton from "../../UI/buttons/DismissButton";
import { Text } from "../../UI/Text";
import Icon from "../../UI/Icon";
import { SadFace } from "../../UI/icons";
import BackButton from "./buttons/BackButton";
import { FlexBox } from "@/UI/Containers";
import Modal from "@/UI/Modal";

export default function Failure({ isShown, text, handleClose, ...rest }) {
  return (
    <Modal
      hide={handleClose}
      isShown={isShown}
      width={{ _: "93%", desktop: 850 }}
      p="32px 0"
    >
      <FlexBox dir="column" justify="center" minH={320} mt={32}>
        <Icon
          aria="Sad emoji due to failure"
          transform={{ _: "scale(0.6)", desktop: "scale(1)" }}
        >
          <SadFace />
        </Icon>
        <Text size="2rem" color="#FF2A5A" {...rest}>
          {text}
        </Text>
        <BackButton onClick={handleClose} />
        <DismissButton
          onClick={handleClose}
          transform="scale(0.6)"
          stroke="#B5B5B5"
        />
      </FlexBox>
    </Modal>
  );
}
