import { useAtom, useAtomValue } from "jotai";
import { userBalanceAtom, userWalletAtom } from "src/store";
import { claimModalsAtom } from "src/store/modals";
import useClaim from "hooks/useClaim";
import styled, { Box } from "@xstyled/styled-components";
import ClaimModal from "./ClaimModal";
import FailureModal from "./Failure";
import TransparentButton from "@/UI/buttons/TransparentButton";
import GradientButtonYellow from "@/UI/buttons/GradientButtonYellow";
import { FlexBox } from "@/UI/Containers";
import { Text } from "@/UI/Text";
import Loader from "@/UI/Loader";

const ClaimDetails = styled.div`
  margin-top: 32px;

  dl {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 32px;

    dt {
      text-align: right;
      color: #a3a3a3;
    }

    dd {
      margin: 0;
    }
  }
`;

export default function ClaimModals() {
  const { network } = useAtomValue(userWalletAtom);
  const { unclaimed, locked, unlocked } = useAtomValue(userBalanceAtom);
  const [claimModals, setClaimModals] = useAtom(claimModalsAtom);

  const { handleClaim, isSubmitting } = useClaim();

  const dismissModal = () => {
    setClaimModals({
      ...claimModals,
      open: false,
    });
  };

  const isPolygon = network === "polygon";
  const claimingLocked = !!claimModals.lockedTokens;
  const toClaim = isPolygon ? unlocked : unclaimed;
  const total = claimingLocked ? locked : toClaim;

  return (
    <>
      {claimModals.open && (
        <ClaimModal claim isShown={claimModals.open} handleClose={dismissModal}>
          {!isSubmitting ? (
            <FlexBox dir="column">
              <Text
                m={0}
                size={{ _: "1rem", desktop: "1.5rem" }}
                font="Medium"
                align="center">
                Confirm claiming
              </Text>

              <ClaimDetails>
                {!claimingLocked && (
                  <dl>
                    <dt>Unclaimed:</dt>
                    <dd>{toClaim % 1 !== 0 ? toClaim.toFixed(4) : toClaim}</dd>
                  </dl>
                )}
                {claimingLocked && (
                  <dl>
                    <dt>Locked:</dt>
                    <dd>{locked % 1 !== 0 ? locked.toFixed(4) : locked}</dd>
                  </dl>
                )}
                <Box as="dl" mt={22}>
                  <Box as="dt" color="#fff">
                    Total:
                  </Box>
                  <dd>{total % 1 !== 0 ? total.toFixed(4) : total}</dd>
                </Box>
                <dl>
                  <dt>Fee:</dt>
                  <dd>
                    {claimingLocked ? ((total / 100) * 35).toFixed(4) : 0}
                  </dd>
                </dl>
              </ClaimDetails>

              <FlexBox mt={42} mb={{ _: 16, desktop: 0 }} spaceX={32}>
                <TransparentButton w={150} h={55} onClick={dismissModal}>
                  CANCEL
                </TransparentButton>
                <GradientButtonYellow
                  w={150}
                  h={55}
                  letterSpacing="0.1em"
                  onClick={() => handleClaim()}>
                  CLAIM
                </GradientButtonYellow>
              </FlexBox>
            </FlexBox>
          ) : (
            <FlexBox dir="column">
              <Text size="1.5rem">Please hold on...</Text>
              {isPolygon ? (
                <Text size="1.25rem" align="center">
                  Tokens are being transferred.
                </Text>
              ) : (
                <Text size="1.25rem" align="center">
                  Tokens will be transferred from ETH to Polygon. <br></br>This
                  may take up to 1 hour.
                </Text>
              )}
              <Box transform="scale(1.5) translateX(-50%)" mt={32}>
                <Loader />
              </Box>
            </FlexBox>
          )}
        </ClaimModal>
      )}

      {claimModals.success && (
        <ClaimModal
          isShown={claimModals.success}
          handleClose={() =>
            setClaimModals({
              ...claimModals,
              success: false,
            })
          }>
          <Text
            size={{ _: "1.25rem", desktop: "1.5rem" }}
            font="Bold"
            align="center">
            Thank you for claiming WOF utility tokens!
          </Text>
          <Text
            size={{ _: "1rem", desktop: "1.5rem" }}
            m="-20 0 88"
            align="center">
            Tokens will be credited to your wallet on Polygon. <br></br>
            {!isPolygon ? "This may take up to 1 hour." : ""}
          </Text>
        </ClaimModal>
      )}

      {claimModals.failure && (
        <FailureModal
          mb={88}
          text="Transaction failed!"
          isShown={claimModals.failure}
          handleClose={() =>
            setClaimModals({
              ...claimModals,
              failure: false,
            })
          }
        />
      )}
    </>
  );
}
