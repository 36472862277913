import Button from "../Button";

export default function TransparentButton({ children, ...rest }) {
  return (
    <Button
      p="18px 32px"
      fontFamily="Lufga-Medium"
      color="#fff"
      hoverColor="#FFBB06"
      border="1px solid #BFBFBF"
      hoverBorder="1px solid #FFBB06"
      borderRadius={5}
      letterSpacing="0.1em"
      {...rest}
    >
      {children}
    </Button>
  );
}
