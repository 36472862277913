import { useState } from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import toast from "react-hot-toast";
import { useAtom, useAtomValue } from "jotai";
import { userWalletAtom } from "../store";
import { claimModalsAtom } from "../store/modals";
import tokenContractJSON from "../pages/api/data/FxMintableERC20RootTunnel.json";
import racingContractJSON from "../pages/api/data/WofRacingContract.json";
import createTransaction from "src/utils/createTransaction";
import { RACE_CONTRACT_ADDRESS } from "../constants";
import logError from "src/utils/logError";

export default function useClaim() {
  const channelEth = "0x25B56ACB7527249Ce21DC3431527681D44779880";
  const childTunnel = "0x7f8a13a102aaaa5fc3ab87e5162b1b50e022d1d2";

  const wallet = useAtomValue(userWalletAtom);
  const [claim, setClaimModals] = useAtom(claimModalsAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSuccess = () => {
    setClaimModals({
      open: false,
      lockedTokens: false,
      success: true,
      failure: false,
    });
    setIsSubmitting(false);
    toast.success("Tokens successfully claimed");
  };
  const handleError = () => {
    setClaimModals({
      open: false,
      lockedTokens: false,
      success: false,
      failure: true,
    });
    setIsSubmitting(false);
    toast.error("Something went wrong");
  };

  const claimOnEthereum = async (provider) => {
    const web3 = new Web3(provider);
    const tokenContract = new web3.eth.Contract(
      tokenContractJSON.abi,
      channelEth
    );
    const claimableTokens = await tokenContract.methods
      .getClaimable(wallet.address)
      .call();

    const data = web3.eth.abi.encodeParameters(
      ["address", "address", "uint256"],
      [childTunnel, wallet.address, claimableTokens]
    );

    tokenContract.methods
      .sendClaimable(data)
      .send({
        from: wallet.address,
        to: channelEth,
        maxPriorityFeePerGas: web3.utils.toWei("2", "gwei"),
      })
      .then(() => handleSuccess())
      .catch(() => handleError());
  };

  const claimLockedWinnings = async (provider) => {
    const web3 = new Web3(provider);
    const racingContract = new web3.eth.Contract(
      racingContractJSON.abi,
      RACE_CONTRACT_ADDRESS
    );

    const data = await racingContract.methods.claimLockedWinnings();

    const { tx, error } = await createTransaction({
      data,
      userAddress: wallet.address,
      contractAddress: RACE_CONTRACT_ADDRESS,
    });

    if (error) {
      return handleError();
    }

    data
      .send(tx)
      .on("receipt", () => handleSuccess())
      .catch((error) => {
        logError({ error });
        handleError();
      });
  };

  const claimWinnings = async (provider) => {
    const web3 = new Web3(provider);
    const racingContract = new web3.eth.Contract(
      racingContractJSON.abi,
      RACE_CONTRACT_ADDRESS
    );

    const data = await racingContract.methods.claimWinnings();

    const { tx, error } = await createTransaction({
      data,
      userAddress: wallet.address,
      contractAddress: RACE_CONTRACT_ADDRESS,
    });

    if (error) {
      return handleError();
    }

    data
      .send(tx)
      .on("receipt", () => handleSuccess())
      .catch((error) => {
        logError({ error });
        handleError();
      });
  };

  const handleClaim = async () => {
    setIsSubmitting(true);

    if (wallet.provider === "WalletConnect") {
      const provider = new WalletConnectProvider({
        infuraId: "ec429ca798e74b668fb15ab33206b2d4",
      });
      await provider.enable();

      if (wallet.network === "ethereum") {
        claimOnEthereum(provider);
      }
    } else {
      if (wallet.network === "ethereum") {
        claimOnEthereum(window.ethereum);
      } else if (wallet.network === "polygon") {
        if (claim.lockedTokens) {
          claimLockedWinnings(window.ethereum);
        } else {
          claimWinnings(window.ethereum);
        }
      }
    }
  };

  return { handleClaim, isSubmitting };
}
