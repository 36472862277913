import Modal from "@/UI/Modal";
import Button from "@/UI/Button";
import DismissButton from "@/UI/buttons/DismissButton";
import Icon from "@/UI/Icon";
import { SadFace } from "@/UI/icons";

export default function ClaimModal({
  isShown,
  children,
  handleClose,
  claim,
  failure,
}) {
  return (
    <Modal
      hide={handleClose}
      isShown={isShown}
      width={{ _: "90%", desktop: 638 }}
      padding={60}
    >
      {failure && (
        <Icon aria="Sad emoji due to transaction failed">
          <SadFace />
        </Icon>
      )}

      {children}

      {!claim && (
        <Button
          onClick={handleClose}
          mt={32}
          color="#fff"
          fontSize={{ _: "1.25rem", sm_desktop: "1.625rem" }}
          textDecoration="underline"
        >
          Close
        </Button>
      )}

      <DismissButton
        onClick={handleClose}
        stroke="#707070"
        transform="scale(0.5)"
      />
    </Modal>
  );
}
