import Web3 from "web3";
import getGasFee from "./getGasFee";
import metaMaskError from "./metaMaskError";

export default async function createTransaction({
  data,
  userAddress,
  contractAddress,
  gasCoefficient = 1.05,
}) {
  const web3 = new Web3(window.ethereum);
  let error;

  const gas = await data.estimateGas({ from: userAddress }).catch((err) => {
    error = metaMaskError(err);
  });
  const nonce = await web3.eth
    .getTransactionCount(userAddress, "latest")
    .catch((err) => {
      error = metaMaskError(err);
    });

  const gasFee = await getGasFee();
  const maxPriorityFeePerGas = web3.utils.toWei(`${gasFee}`, "gwei");

  const tx = {
    from: userAddress,
    to: contractAddress,
    data: data.encodeABI(),
    gas: Math.ceil(gas * gasCoefficient),
    maxPriorityFeePerGas,
    nonce,
  };

  return { tx, error };
}
